export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const TOGGLE_BOXED_LAYOUT = 'TOGGLE_BOXED_LAYOUT';
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const TOGGLE_OFFCANVAS_NAV = 'TOGGLE_OFFCANVAS_NAV';
export const TOGGLE_FIXED_SIDENAV = 'TOGGLE_FIXED_SIDENAV';
export const TOGGLE_FIXED_HEADER = 'TOGGLE_FIXED_HEADER';
export const CHANGE_SIDENAV_WIDTH = 'CHANGE_SIDENAV_WIDTH';
export const TOGGLE_OFFCANVAS_MOBILE_NAV = 'TOGGLE_OFFCANVAS_MOBILE_NAV';
export const CHANGE_COLOR_OPTION = 'CHANGE_COLOR_OPTION';
export const CHANGE_THEME = 'CHANGE_THEME';
export const MULTIPLE_FILES = 'MULTIPLE_FILES';
export const CLEAR_MULTIPLE_FILES = 'CLEAR_MULTIPLE_FILES';
export const BUTTON_VALUE = 'BUTTON_VALUE';
export const TABLE_FILELIST = 'TABLE_FILELIST';
export const UPDATE_FILE_STATUS = 'UPDATE_FILE_STATUS';
export const FILE_LIST = 'FILE_LIST';
export const PERMISSIONS = 'PERMISSIONS';
export const SET_DOWNLOAD_LIMIT = 'SET_DOWNLOAD_LIMIT';
export const SET_MAX_DOWNLOAD_LIMIT = 'SET_MAX_DOWNLOAD_LIMIT';