import React from 'react';
import { Layout } from 'antd';
import PAGEROUTES from 'constants/pageRoutes';
import { FaLinkedin, FaYoutube, FaTwitter, FaFacebookSquare, FaInstagram } from 'react-icons/fa';
import './style.scss'
const { Footer } = Layout;

const AppFooter = () => (
  // <Footer className="app-footer bg-white">
  //   <div className="footer-inner-v2">
  //     <ul className="list-inline list-inline-split">
  //       <li className="list-inline-item"><a id="Partner_About" href={PAGEROUTES.footerLinks.About} target="_blank">About</a></li>
  //       <li className="list-inline-item"><a id="Partner_Careers" href={PAGEROUTES.footerLinks.Career} target="_blank">Careers</a></li>
  //       <li className="list-inline-item"><a id="Partner_Help&Support" href={PAGEROUTES.footerLinks.Help} target="_blank">Help &amp; Support</a></li>
  //       <li className="list-inline-item"><a id="Partner_Privacy" href={PAGEROUTES.footerLinks.Privacy} target="_blank">Privacy</a></li>
  //       <li className="list-inline-item"><a id="Partner_Terms" href={PAGEROUTES.footerLinks.Terms} target="_blank">Terms</a></li>
  //     </ul>
  //     <ul className="footer-social-list">
  //       <li><a id="Partner_LinkedIn" href={PAGEROUTES.footerLinks.LinkedIn} target="_blank" rel="noopener noreferrer" ><Icon type="linkedin" /></a></li>
  //       <li><a id="Partner_FaceBook" href={PAGEROUTES.footerLinks.Facebook} target="_blank" rel="noopener noreferrer" ><Icon type="facebook" /></a></li>
  //       <li><a id="Partner_Twitter" href={PAGEROUTES.footerLinks.Twitter} target="_blank" rel="noopener noreferrer" ><Icon type="twitter" /></a></li>
  //       <li><a id="Partner_Instagram" href={PAGEROUTES.footerLinks.Instagram} target="_blank" rel="noopener noreferrer"><Icon type="instagram" /></a></li>
  //       <li><a id="Partner_YouTube" href={PAGEROUTES.footerLinks.YouTube} target="_blank" rel="noopener noreferrer"><Icon type="youtube" /></a></li>
  //     </ul>
  //     <div className="footer-copyright">
  //       <span>© 2020 4GEN Technologies Pvt. Ltd. All Rights Reserved.</span>
  //     </div>
  //   </div>
  // </Footer>
  <Footer className="footer-wrapper">
  <div className="container-fluid">
      <div className="row">
          <div className="col-md-4 footer-box text-center">
              <img style={{ height: '35px' }} src='https://shenzyn-uploads.s3.ap-south-1.amazonaws.com/Assets/Shenzyn-TM.svg' alt="Shenzyn Logo " />
              <p className="m-0">
                  <a id="whereareyou_shenzyn" className="footer-link" href="mailto:wehearyou@shenzyn.com" >wehearyou@shenzyn.com</a>
              </p>
          </div>
          <div className="col-md-4 text-center mt-2">
              <div>
              <a id="Partner_Privacy" href={PAGEROUTES.footerLinks.Privacy} className="footer-links-a" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
              </div>
              <div>
              <a id="Partner_Terms" href={PAGEROUTES.footerLinks.Terms} className="footer-links-a" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
              </div>
                      
                      
                      
                  </div>
          <div className="col-md-4 footer-box text-center">
              <p className="m-0 p-0">You can find us at</p>
              <ul className="footer-social-list">
                  <li><a id="instagram" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/we_shenzyn/" className="footer-social-icons"><FaInstagram style={{ color: '#B01D86' }} /></a></li>
                  <li><a id="linked_in" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/28714426/admin/" className="footer-social-icons"><FaLinkedin style={{ color: '#2F78B7' }} /></a></li>
                  <li><a id="youtube" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCJ3hSpePirYqXCAJo75OIhQ" className="footer-social-icons"><FaYoutube style={{ color: '#F0210F' }} /></a></li>
                  <li><a id="twitter" target="_blank" rel="noopener noreferrer" href="https://twitter.com/Shenzyn_EW" className="footer-social-icons"><FaTwitter style={{ color: '#2F78B7' }} /></a></li>
                  <li><a id="facebook" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Shenzyn-108397103967073/" className="footer-social-icons"><FaFacebookSquare style={{ color: '#475993' }} /></a></li>
              </ul>
          </div>
      </div>
  </div>
</Footer>
)

export default AppFooter;
