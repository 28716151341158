import React from 'react';
// import Header from '../../routes/layout/routes/header/components/Headers'
import Footer from './../Layout/Footer'
import Error from './error'

const NotFoundPage = () => {
  return (
    <>
    {/* <Header/> */}
    <Error link = '/'height="81vh"/>
      <Footer/> 


  </>
  )
}

export default NotFoundPage;
